import store from '@/store'
import {userRepository} from '@/app/user/user.repository'
import {notificationsHelper} from '@/helpers/notifications.helper'
import {prepareData} from "@/utils/prepareData";

class UserService {

    async getUserInfo() {
        try {
            const data = await userRepository.getUserInfo()
            store.commit('setUserInfo', data)

            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async patchUserInfo(userInfo) {
        try {
    
            const requestData = prepareData(userInfo,
                [
                    { key: 'birthday', rule: 'emptyStringNull' },
                    'fullName',
                    'phoneNumber',
                    { key: 'location', rule: 'emptyStringNull' },
                    { key: 'sex', rule: 'emptyStringNull' }
                ])
    
    
            const data = await userRepository.patchUserInfo(requestData)
            store.commit('setUserInfo', data)

            notificationsHelper.success('Профиль успешно обновлен')
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: true, backend: false})
            throw error
        }
    }

    async getAvatar() {
        try {
            if(!store.state.user.info.avatar) {

                const response = await userRepository.getAvatar()
                store.commit('setUserAvatar', response)

                return Promise.resolve(response)
            }
            else{
                return Promise.resolve(store.state.user.info.avatar)
            }
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }
    async addAvatar(fileAvatar) {
        try {
            const formData = new FormData()
            formData.append('file',fileAvatar)
    
            const response = await userRepository.addAvatar(formData)
            store.commit('setUserAvatar', response)
    
            return Promise.resolve(response)
        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: true, backend: { find: 'File too large', set: 'Слишком большое изображение' } })
            throw error
        }
    }
    async deleteAvatar() {
        const response = await userRepository.deleteAvatar()

        store.commit('deleteUserAvatar')
        return Promise.resolve(response.data)
    }
    async updateAvatar(fileAvatar) {
        try {
            const formData = new FormData()
            formData.append('file',fileAvatar)
    
            const data = await userRepository.updateAvatar(formData)
    
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: true, backend: { find: 'File too large', set: 'Слишком большое изображение' } })
            throw error
        }
    }

    async updatePassword(password,newPassword){
        try {
            const data = await userRepository.updatePassword(password,newPassword)
            notificationsHelper.success('Пароль обновлен')
            return Promise.resolve(data)

        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: 'Неверный пароль', backend: false })
            return Promise.reject(error)
        }

    }

}

export const userService = new UserService()
