import http from '@/app/user/config/user-axios.config'

export default class AuthRepository {

    /**
     * @param {object} requestData
     * @param {string} requestData.email
     * @param {string} requestData.password
     * */
    static async auth(requestData) {
        const response = await http.post(`/spa/login`, requestData)
        return Promise.resolve(response.data)
    }

    static async register(requestData, params) {
        const response = await http.post(`/register`, requestData, params)
        return Promise.resolve(response.data)
    }

    static async appendInfoForNewUser(requestData,token,params) {
        const response = await http.post(`/append-information/${token}`, requestData,params)
        return Promise.resolve(response.data)
    }

    static async confirmEmail(token) {
        const response = await http.get(`/email-activation/${token}`)
        return Promise.resolve(response.data)
    }

    static async sendNewPassword(email){
        const response = await http.post(`/reset-password`,{email:email})
        return Promise.resolve(response)
    }

    static async isValidToken(token){
        const response = await http.get(`/reset-password/${token}`)
        return Promise.resolve(response.data)
    }

    static async updatePassword(token,newPassword){
        const response = await http.post(`/reset-password/${token}`,{newPassword: newPassword})
        return Promise.resolve(response.data)
    }

    static async isValidTokenForAppendInfo(token){
        const response = await http.get(`/append-information/${token}`)
        return Promise.resolve(response.data)
    }


}
