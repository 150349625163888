import http from '@/app/user/config/user-axios.config'

class UserRepository {

    async getUserInfo() {
        const response = await http.get('/spa/me')
        return Promise.resolve(response.data.me)
    }

    async patchUserInfo(requestData) {
        const response = await http.patch('/spa/user', requestData)
        return Promise.resolve(response.data)
    }

    async getAvatar() {
        const response = await http.get('/spa/user/avatar', {responseType: 'blob'})
        const url = window.URL.createObjectURL(response.data)

        return Promise.resolve(url)
    }

    async addAvatar(fileAvatar) {
        const response = await http.put('/spa/user/avatar', fileAvatar)
        return Promise.resolve(response.data)
    }

    async deleteAvatar() {
        const response = await http.delete('/spa/user/avatar')
        return Promise.resolve(response.data)
    }

    async updateAvatar(fileAvatar) {
        const response = await http.patch('/spa/user/avatar', fileAvatar)
        return Promise.resolve(response.data)
    }

    async updatePassword(password, newPassword) {
        const checkPassword = await this.checkPassword(password)
        if (checkPassword.status !== 400) {
            await this.patchUserInfo({password: newPassword})
        }
    }

    async checkPassword(password) {
        const response = await http.post('/spa/user/check-password', {password: password})
        return Promise.resolve(response.data)
    }

}

export const userRepository = new UserRepository()
