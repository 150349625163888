/**
 * @param {Object<string, any>} data
 * @param {Array | Boolean} options
 */
export function prepareData(data, options) {
    if (options === false) return data
    const result = {}
    for (const option of options) {
        if (typeof option === 'string') {
            if (data[option] !== null && data[option] !== undefined)
                result[option] = data[option]
        } else if (typeof option === 'object') {
            if (option.rule === 'acceptNull') {
                result[option.key] = typeof data[option.key] === 'string' && data[option.key].length === 0 ? null : data[option.key]
            } else if (option.rule === 'emptyStringNull') {
                result[option.key] = data[option.key] === '' || data[option.key] === undefined ? null : data[option.key]
            } else if (option.rule === 'emptyStringDelete' && data[option.key]) {
                if (data[option.key] !== '') result[option.key] = data[option.key]
            }
        }
    }
    return result
}
