import axios from 'axios'
import store from '@/store'
import {decodeData, encodeData} from '@/utils/HTTPDataParser'
import {authService} from '@/app/user/auth/auth.service'

const http = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_HOST
})

http.defaults.headers.common['Content-Type'] = 'application/json'

http.interceptors.request.use( async function (config) {
    if (store.state.user.accessToken) {
        config.headers.common['Authorization'] = `Bearer ${store.state.user.accessToken}`

        const exp = store.state.user.jwtExp

        if (config.url !== '/spa/refresh' && config.url !== '/logout') {
            if (exp - Date.now() <= process.env.VUE_APP_REMAINING_TIME_TO_REFRESH_TOKEN)  {

                await authService.refreshToken()
                const token = store.state.user.accessToken

                config.headers.common['Authorization'] = `Bearer ${token}`
                config.data = decodeData(config.data)

                return config
            } else {
                config.data = decodeData(config.data)
                return config
            }
        } else {
            config.data = decodeData(config.data)
            return config
        }
    } else {
        config.data = decodeData(config.data)
        return config
    }
})

http.interceptors.response.use((response) => {
    if (response.headers.contentType === 'application/json' || response.headers['content-type'] === 'application/json')
        response.data = encodeData(response.data)
    return response
}, (error) => {
    if (error.response?.status === 401) {
        authService.logout()
    }
    throw error
})

export default http
