import AuthRepository from '@/app/user/auth/auth.repository'
import store from '@/store/index'
import router from '@/router'
import {notificationsHelper} from '@/helpers/notifications.helper'
import {userService} from "../user.service";
import http from "../config/user-axios.config";
import cookie from 'js-cookie'
class AuthService {

    logout() {
        store.commit('logout')
        store.commit('userStoreDestroy')
        if (router.currentRoute.meta.requiresAuth)
            router.push('/')
    }

    async auth(requestData) {
        try {
            const data = await AuthRepository.auth(requestData)
            store.commit('login', data)

            await userService.getUserInfo()

            notificationsHelper.success('Добро пожаловать')
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base:'Не верные данные', backend: false})
            throw error
        }
    }

    async register(requestData) {
        try {
            let utmInfo = null
            if(cookie.get('utmInfo')) {
                utmInfo = {
                    params: JSON.parse(cookie.get('utmInfo'))
                }
            }

            const data = await AuthRepository.register(requestData, utmInfo)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base:'Пользователь с такой почтой существует', backend: false})
            throw error
        }
    }
    async appendInfoForNewUser(requestData,token) {
        try {
            let utmInfo = null
            if(cookie.get('utmInfo')) {
                utmInfo = {
                    params: JSON.parse(cookie.get('utmInfo'))
                }
            }
            const data = await AuthRepository.appendInfoForNewUser(requestData,token,utmInfo)
            //notificationsHelper.success('Пожалуйста, подвердите учетную запись на электронной почте')
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: 'Ссылка не действительна', backend: false })
            router.replace('/')
            throw error
        }
    }

    async confirmEmail(token) {
        try {
            const data = await AuthRepository.confirmEmail(token)
            notificationsHelper.success('Электронная почта подтверждена')
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: 'Пользователь не существует или ссылка не действительна', backend: false})
            throw error
        }
    }

    async sendNewPassword(email){
        try {
            const response = await AuthRepository.sendNewPassword(email)
            notificationsHelper.success('Ссылка на восстановление пароля отправлена на почту')

            return Promise.resolve(response)
        } catch (error) {
            notificationsHelper.fromHttpError(error)
            throw error
        }
    }

    async isValidToken(token){
        try {
            const response = await AuthRepository.isValidToken(token)
            return Promise.resolve(response)
        }
        catch (error){
            notificationsHelper.fromHttpError(error, { base: 'Срок ссылки истек', backend: false })
            router.push('/login')
            return Promise.reject(error)
        }
    }
    async isValidTokenForAppendInfo(token){
        try {
            const response = await AuthRepository.isValidTokenForAppendInfo(token)
            return Promise.resolve(response)
        }
        catch (error){
            notificationsHelper.fromHttpError(error, { base: 'Срок ссылки истек', backend: false })
            router.replace('/')
            return Promise.reject(error)
        }
    }
    async updatePassword(token,newPassword){
        const response = await AuthRepository.updatePassword(token,newPassword)
        return Promise.resolve(response.data)
    }

    async refreshToken(){
        try {
            if (!store.state.user.refreshPromise) {
    
                const promise = http.post('/spa/refresh', {
                    refresh_token: store.state.user.refreshToken
                })
                store.commit('setUserRefreshPromise', promise)
    
                const response = await promise
                const accessToken = response.data.accessToken

                store.commit('setUserRefreshPromise', null)
                store.commit('setAccessToken', accessToken)
                
                return promise
            } else {
                return store.state.user.refreshPromise
            }
        } catch (error) {
            console.log(error)
            store.commit('logout')
            throw error
        } finally {
            store.commit('setUserRefreshPromise', null)
        }
    }


}

export const authService = new AuthService()
